import React, { useContext } from 'react';
import {
  Grid,
  Box,
  Typography,
  SxProps,
} from '@mui/material';
import { PTSubmissionSteps } from '../../../variables/types';
import { PromptFile } from '../../../generated/graphql';
import AudioPlayer from '../../../components/Player';
import { SubmitTaskContext } from '../submit-task-context';
import { SPEAKING_DEFAULT_PROMPT_TEXT, WRITING_DEFAULT_PROMPT_TEXT } from '../../../variables/constant';
import { palette  } from '../../../theme/palette';
import { unescapeSpecialCharacters } from '../../../utils/handleEscapeCharacters';
import { isIOS } from 'react-device-detect';

interface SpeakingWritingPromptProps {
  taskType: string,
  setIsPlaying?: Function,
  defaultPromptAudioUrl?: string,
}

interface SpeakingWritingAudioPromptProps {
  content: Pick<PromptFile, 'id' | 'url' | 'file_type'> | undefined,
  defaultPromptAudioUrl?: string,
  active: boolean,
  setIsPlaying?: Function,
}

export const styles = {
  playerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  promptContentContainer: {
    display: 'flex',
    alignItems: 'center',
    overflowY: 'scroll',
    border: '1px solid',
    borderColor:palette.customBlue.primaryBlue,
    borderRadius: 2,
    padding: 1,
  },
  promptContentText: {
    fontSize: '15px',
    letterSpacing: '1px',
    color: palette.fontColors.fontBlack,
    fontWeight: 700,
  },
  promptContentTextIOS: {
    fontSize: '13px',
    color: palette.fontColors.fontBlack,
    fontWeight: 700,
  },
};

const SpeakingWritingAudioPrompt = ({
  content,
  defaultPromptAudioUrl,
  active = true,
  setIsPlaying,
}: SpeakingWritingAudioPromptProps) => {
  return (
    <>
      {
        content || defaultPromptAudioUrl ? (
          <AudioPlayer
            url={content?.url ?? defaultPromptAudioUrl}
            fileType={content?.file_type}
            active={active}
            disablePlay={!active}
            ptSubmissionPlayer
            setIsPlaying={setIsPlaying}
          />
        ) : ('')
      }
    </>
  );
};

const SpeakingWritingPrompt = ({
  taskType,
  setIsPlaying,
  defaultPromptAudioUrl,
}: SpeakingWritingPromptProps) => {

  const {
    submission,
    playing,
    recording,
  } = useContext(SubmitTaskContext);

  const benchmarkOrAssignment = submission?.assignment ?? submission?.getBenchmark;
  let promptContent: string | undefined = '';
  let promptAudioUrl: string | undefined = '';
  let promptRecording: Pick<PromptFile, 'id' | 'url' | 'file_type'> | undefined;
  if (taskType === PTSubmissionSteps.Speaking || benchmarkOrAssignment?.is_common_prompt) {
    promptContent = benchmarkOrAssignment?.speaking_prompt;
    if (benchmarkOrAssignment?.speaking_prompt_file) promptRecording = benchmarkOrAssignment?.speaking_prompt_file;
  } else {
    promptContent = benchmarkOrAssignment?.writing_prompt ?? '';
    if (benchmarkOrAssignment?.writing_prompt_file) promptRecording = benchmarkOrAssignment?.writing_prompt_file;
  }
  if (!promptContent) {
    if (taskType === PTSubmissionSteps.Speaking) promptContent = SPEAKING_DEFAULT_PROMPT_TEXT;
    if (taskType === PTSubmissionSteps.Writing) promptContent = WRITING_DEFAULT_PROMPT_TEXT;
    promptAudioUrl = defaultPromptAudioUrl;
  }

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {taskType !== PTSubmissionSteps.Labeling && (
        <Grid item xs={1}>
          <SpeakingWritingAudioPrompt
            content={promptRecording}
            defaultPromptAudioUrl={promptAudioUrl}
            active={!(playing || recording)}
            setIsPlaying={setIsPlaying}
          />
        </Grid>
      )}
      <Grid item xs={9} ml={2}>
        <Box sx={{ ...styles.promptContentContainer } as SxProps}>
          <Typography variant="body1" gutterBottom sx={{ ...(isIOS ? styles.promptContentTextIOS : styles.promptContentText) }}>
            {unescapeSpecialCharacters(promptContent || '')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SpeakingWritingPrompt;
