import React, { useRef, useState } from 'react';
import { Box, IconButton, styled, SxProps, Tooltip } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import { AnnotationInterface } from '../../../variables/types';
import { POPUP_HEIGHT, POPUP_WIDTH, PREVIEW_GRADIENT_HEIGHT } from '../../../variables/constant';
import StyledEmptyTag from './ClickAndLabel.styled';

// @ts-ignore
import Tag from './Tag';
import NextButton from '../Common/NextButton';
import {
  SubmissionStatus,
  BackPackSubmissionFragment,
  StudentSubmissionFragmentFragment,
  NewSubmissionScoreFragmentFragment,
  BenchmarkSubmissionScoreFragmentFragment,
} from '../../../generated/graphql';
import AddTagForm from './AddTagForm';
import FullScreenIcon from '../../../components/Icons/FullScreenIcon';
import SaveAndQuitButon from '../Common/SaveAndQuitButon';
import isSafari from '../../../utils/isSafari';
import { palette  } from '../../../theme/palette';

export interface NewTag {
  x?: number,
  y?: number,
}

const styles = {
  image: {
    width: '100%',
    height: '100%',
  },
  svgWrapper: {
    overflow: 'visible',
    transform: 'translateZ(0)',
  },
  root: {
    width: '100%',
  },
  fixed: {

  },
  previewPatch: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundImage: palette.gradients.overlayShadowGradient,
  },
  fullScreenIconBtn: {
    background: palette.customWhite.main,
    '&.MuiIconButton-root': {
      marginTop: 1.25,
      marginRight: 2.5,
      '&.MuiButtonBase-root': {
        position: 'static',
      },
    },
  },
  fullScreenIcon: {
    '&.MuiSvgIcon-root': {
      height: 50,
      width: 50,
    },
  },
};

const StyledGFixed = styled('g')({
  position: 'fixed',
});

interface Prop {
  submission?: StudentSubmissionFragmentFragment |
  NewSubmissionScoreFragmentFragment |
  BackPackSubmissionFragment |
  BenchmarkSubmissionScoreFragmentFragment |
  null,
  annotations?: AnnotationInterface[],
  handleCreateAnnotations?: Function,
  setShowQuitConfirmation?: Function,
  disableEdit: boolean,
  containerHeight?: string,
  updateStatus?: Function,
  updateSubmissionStatus?: Function,
  togglePreview?: (event: React.MouseEvent<HTMLButtonElement | SVGElement, MouseEvent>) => void,
}

const ClickAndLabelTask = ({
  submission,
  annotations,
  handleCreateAnnotations,
  containerHeight,
  togglePreview,
  disableEdit,
  updateSubmissionStatus,
}: Prop) => {
  const imageRef = useRef <SVGImageElement | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [imageWidth, setImageWidth] = useState<number | string | undefined>(0);
  const [imageHeight, setImageHeight] = useState<number | string | undefined>(0);
  const [newTag, setNewTag] = useState<NewTag | null>(null);

  const disableAnnotationEdit = disableEdit || !!newTag;

  // @ts-ignore - To suppress lint errors/warnings on assignment/getBenchmark/benchmark not found.
  const image = {
    width: (
      // @ts-ignore
      submission?.assignment?.resource?.image_width ||  // @ts-ignore
      submission?.getBenchmark?.resource?.image_width ||  // @ts-ignore
      submission?.benchmark?.resource?.image_width ||
      0
    ),
    height: (
      // @ts-ignore
      submission?.assignment?.resource?.image_height || // @ts-ignore
      submission?.getBenchmark?.resource?.image_height || // @ts-ignore
      submission?.benchmark?.resource?.image_height ||
      0
    ),
  };

  const resourceUrl = (
    // @ts-ignore
    submission?.assignment?.resource?.file.url ?? // @ts-ignore
    submission?.getBenchmark?.resource?.file?.url ?? // @ts-ignore
    submission?.benchmark?.resource?.file?.url ??
    ''
  );

  const onImageLoad = () => {
    const box = imageRef?.current?.getBoundingClientRect();
    setImageWidth(box?.width);
    setImageHeight(box?.height);
  };

  const handleNext = () => {
    handleCreateAnnotations?.();
    updateSubmissionStatus?.(SubmissionStatus.AnnotationCompleted);
  };

  const handleAddTag = (
    event: React.MouseEvent<SVGImageElement, MouseEvent>,
  ) => {
    if (disableAnnotationEdit) {
      return;
    }
    setNewTag(null);
    event.preventDefault();
    event.stopPropagation();
    const pt = svgRef?.current?.createSVGPoint();
    if (pt) {
      pt.x = event.clientX;
      pt.y = event.clientY;
      const svgInverse = svgRef?.current?.getScreenCTM()?.inverse();
      if (svgInverse) {
        const svgP = pt?.matrixTransform(svgInverse);
        setNewTag((prevState) => ({
          ...prevState,
          x: svgP.x,
          y: svgP.y,
        }));
      }
    }
  };

  const renderTags = () => annotations?.map((tag: AnnotationInterface, index: number) => (
    <Tag
      tag={tag}
      key={tag.id}
      index={index}
      svgRef={svgRef}
      maxHeight={image.height}
      maxWidth={image.width}
      disableEdit={disableEdit}
    />
  ));

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      sx={{ ...styles.root }}
    >
      <Box width="100%">
        <svg
          width="100%"
          height={containerHeight || '700px'}
          ref={svgRef}
          style={{ ...styles.svgWrapper }}
          viewBox={`0 0 ${image.width} ${image.height}`}
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
        >
          <StyledGFixed width={`${imageWidth}px`} height="100%">
            {(resourceUrl) && (
              <image
                ref={imageRef}
                width="100%"
                onLoad={onImageLoad}
                onClick={handleAddTag}
                height="100%"
                viewBox={`0 0 ${image.width} ${image.height}`}
                href={resourceUrl}
              />
            )}
            {resourceUrl && isSafari && (
              <image
                ref={imageRef}
                width="100%"
                onLoad={onImageLoad}
                onClick={handleAddTag}
                height="100%"
                viewBox={`0 0 ${image.width} ${image.height}`}
                href={resourceUrl}
                onMouseEnter={togglePreview}
              />
            )}
            <StyledGFixed width={`${imageWidth}px`} height={imageHeight ? `${imageHeight}px` : '100%'}>
              {renderTags()}
              {newTag && (
                <>
                  <foreignObject
                    x={newTag?.x ? newTag?.x - (POPUP_WIDTH / 2) : 0}
                    y={newTag?.y ?? 0}
                    width={POPUP_WIDTH}
                    height={20}
                    style={{ overflow: 'visible' }}
                  >
                    {/*
                    // @ts-ignore */}
                    <div xmlns="http://www.w3.org/1999/xhtml">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <StyledEmptyTag />
                      </Box>
                    </div>
                  </foreignObject>
                  <foreignObject
                    x={newTag?.x ? newTag?.x - (POPUP_WIDTH / 2) : 0}
                    y={newTag?.y ? newTag?.y + 30 : 0}
                    width={POPUP_WIDTH}
                    height={POPUP_HEIGHT}
                    style={{ overflow: 'visible' }}
                  >
                    <AddTagForm newTag={newTag} setNewTag={setNewTag} />
                  </foreignObject>
                </>
              )}
            </StyledGFixed>
            {!!togglePreview && (
              <foreignObject
                width={`${image.width}px`}
                height={PREVIEW_GRADIENT_HEIGHT}
                x={0}
                y={image.height - PREVIEW_GRADIENT_HEIGHT}
              >
                {!isSafari && (
                  <Box sx={{ ...styles.previewPatch } as SxProps}>
                    <Tooltip title="Preview" aria-label="Preview">
                      <IconButton
                        aria-label="Image Preview"
                        onClick={togglePreview}
                        disableTouchRipple
                        disableRipple
                        sx={{ ...styles.fullScreenIconBtn }}
                      >
                        <FullScreenIcon
                          pathFill={palette.customBlue.primaryBlue}
                          sx={{ ...styles.fullScreenIcon }}
                          strokeWidth={40}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </foreignObject>
            )}
          </StyledGFixed>
        </svg>
      </Box>
      { useRouteMatch('/tasks/:id/submit/annotation') && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          minHeight="140px"
          alignItems="end"
        >
          <Box display="inline-flex" justifyContent="space-between">
            <Box>
              <SaveAndQuitButon handleSave={handleCreateAnnotations} />
            </Box>
          </Box>
          <Box>
            <NextButton
              status={SubmissionStatus.Started}
              handleNext={handleNext}
              showConfirmationDialog={!annotations?.length}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(ClickAndLabelTask);
