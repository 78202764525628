import React, { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { matchPath, Route, Switch, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import { NavigationType } from '../../variables/types';
import ClickAndLabelTask from './ClickAndLabel/ClickAndLabelTask';
import SpeakingTask from './SpeakingTask';
import WritingTask from './Writing/WritingTask';
import TopBar from './Common/TopBar';
import { useNavigationProvider } from '../../core/navigationContext';
import { SubmissionStatus } from '../../generated/graphql';
import Loader from '../../components/Loader/loader';
import useSubmitTask from './submit-task-hook';
import { SubmitTaskContext } from './submit-task-context';
import RoutingPaths from '../RoutingPath';

const SubmitTask = () => {
  const {
    submission,
    loading,
    status,
    id,
    showHelp,
    setShowHelp,
    updateStatus,
    showSuccess,
    setShowSuccess,
    handleCreateAnnotations,
    annotations,
    handleAnnotationUpdate,
    deleteAnnotation,
    addAnnotation,
    disableEdit,
    showConfirmation,
    setShowConfirmation,
    speak,
    speaking,
    voices,
    playing,
    setPlaying,
    showGoals,
    setShowGoals,
    currentGoal,
    prevStatus,
    showQuitConfirmation,
    setShowQuitConfirmation,
    updateSubmissionStatus,
    refetch: refreshSubmissionData,
    submissionStep,
    getSubmissionStep,
    setRecording,
    recording,
    isSpeakingWritingPromptPlaying,
    setIsSpeakingWritingPromptPlaying,
  } = useSubmitTask();
  const history = useHistory();
  history.listen(() => {
    getSubmissionStep();
  });
  const { pathname } = history.location;
  const { setNavigationType } = useNavigationProvider();

  const toggleGoalHelpModal = useCallback(() => {
    if (currentGoal) {
      setShowGoals(true);
      setShowHelp(false);
    } else {
      setShowGoals(false);
      setShowHelp(true);
    }
  }, [currentGoal, setShowGoals, setShowHelp]);

  useEffect(() => {
    if (!loading && (!submission?.assignment?.id && !submission?.getBenchmark?.id)) {
      history.replace('/');
    } else if (status !== prevStatus) {
      setNavigationType(NavigationType.None);
      let redirectTo = '';
      switch (status) {
        case SubmissionStatus.Started:
          setShowHelp(true);
          redirectTo = `/tasks/${id}/submit/annotation`;
          break;
        case SubmissionStatus.AnnotationCompleted:
          toggleGoalHelpModal();
          redirectTo = `/tasks/${id}/submit/speaking`;
          break;
        case SubmissionStatus.SpeakingCompleted:
          toggleGoalHelpModal();
          redirectTo = `/tasks/${id}/submit/writing`;
          break;
        default:
          redirectTo = '';
          break;
      }

      if (history.action !== 'POP' && redirectTo != '') {
        history.replace(redirectTo);
      }
    }
  }, [setNavigationType, submission, status, history, id, setShowHelp, loading, setShowGoals, currentGoal, toggleGoalHelpModal, prevStatus, setShowSuccess, setShowConfirmation]);

  if (loading) {
    return <Loader open={loading} />;
  }

  return (
    <Layout padding={1}>
      <SubmitTaskContext.Provider value={{
        submission,
        loading,
        status,
        id,
        showHelp,
        setShowHelp,
        updateStatus,
        showSuccess,
        setShowSuccess,
        handleCreateAnnotations,
        annotations,
        handleAnnotationUpdate,
        deleteAnnotation,
        addAnnotation,
        disableEdit,
        showConfirmation,
        setShowConfirmation,
        speak,
        speaking,
        voices,
        playing,
        setPlaying,
        showGoals,
        setShowGoals,
        currentGoal,
        showQuitConfirmation,
        setShowQuitConfirmation,
        setRecording,
        recording,
        isSpeakingWritingPromptPlaying,
        setIsSpeakingWritingPromptPlaying,

      }}
      >
        <Box
          sx={{
            display:'flex',
            flexDirection:'column',
            width:'100%',
          }}
        >
          <TopBar
            status={status}
            showHelp={showHelp}
            setShowHelp={setShowHelp}
            showGoals={showGoals}
            setShowGoals={setShowGoals}
            currentGoal={currentGoal}
            submissionStep={submissionStep}

          />
          <Box mt={1.25}>
            <ClickAndLabelTask
              submission={submission}
              annotations={annotations}
              disableEdit={!matchPath(pathname, '/tasks/:id/submit/annotation')}
              updateStatus={updateStatus}
              containerHeight="64vh"
              handleCreateAnnotations={handleCreateAnnotations}
              setShowQuitConfirmation={setShowQuitConfirmation}
              updateSubmissionStatus={updateSubmissionStatus}
            />
          </Box>
          <Switch>
            <Route path={RoutingPaths.SpeakingTask}>
              <SpeakingTask id={id} speakings={submission?.speakings!} refreshSubmissionData={refreshSubmissionData} />
            </Route>
            <Route path={RoutingPaths.WritingTask}>
              <WritingTask />
            </Route>
          </Switch>
        </Box>
      </SubmitTaskContext.Provider>
    </Layout>
  );
};

export default React.memo(SubmitTask);
